<template>
  <div style="overflow: hidden;">
    <el-empty description="敬请期待" :image-size="200" size="normal" style="margin-top: 100px;">
      <el-button type="primary" @click="returnHome">返回上一页</el-button>

    </el-empty>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods:{
    returnHome(){
      this.$router.back()
    }
  }
}
</script>

<style>

</style>
